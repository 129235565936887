import React, { useEffect, useRef, useState } from 'react';
import { graphql, type HeadFC, type PageProps, navigate } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { HeaderAndFooterLayout } from '../layouts/HeaderAndFooter';
import { css } from '../../styled-system/css';
import Masonry from 'react-responsive-masonry';
import SEO from '../components/SEO';
import { ThemeChoice } from '../components/photographie/themeChoice';
import ResponsiveImage from '../components/photographie/ResponsiveImage';
import scrollTo from 'gatsby-plugin-smoothscroll';
import ImageGallery from '../components/photographie/ImageGallery';
import ContentBlock from '../components/photographie/ContentBlock';
import ContentfulImageDisp from '../components/photographie/ContentfulImageDisp';
import { useIsMobile } from '../utils/useIsMobile';

interface GalleryPageProps extends PageProps<Query> {
  pageContext: {
    id: string;
    category: string;
    titre: string;
    cover?: string;
    titres: string[];
    DispImage: string[];
    images?: string[];
    HtmlText: string[];
    SEO: {
      title: string;
      description: string;
      robotsContent: string;
    };
  };
}

const getWidth = () =>
  typeof window !== 'undefined'
    ? window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    : 0;

const GalleryPage: React.FC<GalleryPageProps> = ({ data, pageContext }) => {
  const { category, titres, DispImage, images, titre, HtmlText, cover } = pageContext;

  const contentfulImages = data.allContentfulPortfolioImages.edges;

  const [colsAmount, setColsAmount] = useState(1);
  const [width, setWidth] = useState(getWidth());
  const [activeCategory, setActiveCategory] = useState<string | null>(category);

  const sentinelleRef = useRef<HTMLDivElement | null>(null);
  const galerieRef = useRef<HTMLDivElement | null>(null);
  const [isButtonFixed, setIsButtonFixed] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFooterVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (sentinelleRef.current) {
      observer.observe(sentinelleRef.current);
    }

    return () => {
      if (sentinelleRef.current) {
        observer.unobserve(sentinelleRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!galerieRef.current) return;

      const galerieRect = galerieRef.current.getBoundingClientRect();
      const isGalerieFullScreen = galerieRect.top <= 0 && galerieRect.bottom >= window.innerHeight;
      setIsButtonFixed(isGalerieFullScreen && !isFooterVisible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFooterVisible]);

  const handleClickOrder = () => {
    navigate(`/photographie/contact`);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const resizeListener = () => {
        const newWidth = getWidth();
        setWidth(newWidth);
        setColsAmount(newWidth > 900 ? 3 : newWidth > 600 ? 2 : 1);
      };
      window.addEventListener('resize', resizeListener);
      resizeListener();
      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }
  }, []);

  const correctImagesToDisplay = activeCategory === null
    ? contentfulImages
    : contentfulImages.filter(
        ({ node }) => node.category === activeCategory
      );

  const getDispImageData = (imageName: string) => {
    return contentfulImages.find(
      ({ node }) => node.image && node.image.filename === imageName
    )?.node.image.gatsbyImageData;
  };

  return (
    <HeaderAndFooterLayout>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          flexDir: 'column',
          margin: 'auto',
          overflow: 'hidden',
        })}
      >
        <ThemeChoice />
        <h1
          className={css({
            width: '100%',
            fontFamily: 'assistant',
            fontSize: '2rem',
            fontWeight: '400',
            marginTop: '1rem',
            margin: '1rem',
            zIndex: '1',
            '@media (max-width: 768px)': {
              fontSize: '2rem',
              textAlign: 'center',
              padding: '0 10px',
            },
          })}
        >
          {titre}
        </h1>
        <ResponsiveImage
          className={css({
            marginTop: '1rem',
            width: '70%',
            height: '20%',
            objectFit: 'cover',
            marginBottom: '1.25rem',
            zIndex: '-1',
            '@media (max-width: 768px)': {
              width: '100%',
            },
          })}
          imageName={cover ?? ''}
        ></ResponsiveImage>
        <button
          className={`button3 ${css({
            border: 'black 1px solid',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
          })}`}
          onClick={() => scrollTo('#Galerie')}
        >
          Voir toutes les photos de la galerie
        </button>

        {/* Dynamic Content Blocks */}
        {titres.map((title, index) => {
          const imageSetIndex = Math.floor(index / 3); // Display every 3 titles
          const imagesToDisplay = DispImage.slice(imageSetIndex * 4, imageSetIndex * 4 + 4)
            .map(getDispImageData)
            .filter((img) => img);
            
          const validImagesToDisplay = imagesToDisplay.filter(
            (img): img is IGatsbyImageData => img !== undefined
          );

          return (
            <React.Fragment key={`content-block-${index}`}>
              <ContentBlock key={`block-${index}`} title={title} paragraphFile={HtmlText[index]} />
              <div key={`block-images-${index}`} className={css({ width: '100%' })}>
                {index % 3 === 2 && validImagesToDisplay.length > 0 && (
                  <>
                    <ContentfulImageDisp images={validImagesToDisplay} key={`disp-${index}`} />
                    <button
                      key={`button-${index}`}
                      className={`button3 ${css({
                        border: 'black 1px solid',
                        borderRadius: '0.5rem',
                        margin: '2rem auto !important',
                        display: 'flex !important',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                      })}`}
                      onClick={() => scrollTo('#Galerie')}
                    >
                      Voir toutes les photos de la galerie
                    </button>
                  </>
                )}
              </div>
            </React.Fragment>
          );
        })}

        {/* Display the Image Gallery */}
        <ImageGallery images={images || []} />

        <button
          className={`button3 ${css({
            position: isButtonFixed ? 'fixed' : 'static',
            marginBottom: '20px !important',
            bottom: '5%',
            left: '50%',
            transform: 'translateX(-50%)',
            border: 'black 1px solid',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
            zIndex: '100',
            '@media (max-width: 768px)': {
              display: 'block',
              margin: '0 auto 20px !important',
              transform: 'none'
            }
          })}`}
          onClick={handleClickOrder}
        >
          JE RESERVE
        </button>

        <div
          className={css({
            width: '100%',
          })}
          id="Galerie"
          ref={galerieRef}
        >
          <Masonry columnsCount={colsAmount} gutter="5px">
            {correctImagesToDisplay.map(({ node }) =>
              node.image ? (
                <GatsbyImage
                  key={node.id}
                  className={css({
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    w: '100%',
                  })}
                  image={getImage(node.image.gatsbyImageData) as IGatsbyImageData}
                  alt={node.image.title || 'Portfolio Image'}
                />
              ) : null
            )}
          </Masonry>
        </div>
        <div ref={sentinelleRef} style={{ height: '1px' }} />
      </div>
    </HeaderAndFooterLayout>
  );
};

export const query = graphql`
  query GalleryImages($category: [String!]!) {
    allContentfulPortfolioImages(
      filter: { category: { in: $category } }
    ) {
      edges {
        node {
          id
          category
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
            title
            filename
          }
        }
      }
    }
  }
`;

type Query = {
  allContentfulPortfolioImages: {
    edges: {
      node: {
        id: string;
        category: string;
        image: {
          gatsbyImageData: IGatsbyImageData;
          filename: string;
          title: string;
        };
      };
    }[];
  };
};

export default GalleryPage;

export const Head: HeadFC = ({ pageContext }: GalleryPageProps) => {
  return <SEO title={pageContext.SEO.title} description={pageContext.SEO.description} robotsContent={pageContext.SEO.robotsContent} />;
};
